export enum SearchByEnum {
  ALL = 'ALL',
  TITLE = 'TITLE',
  PEN_NAME = 'PEN_NAME',
  WRITER = 'WRITER',
  COVER = 'COVER',
  TAG = 'TAG',
  CHAPTER_NAME = 'CHAPTER_NAME',
  CHARACTER = 'CHARACTER',
  INTRO = 'INTRO',
  CONTENT = 'CONTENT',
}
