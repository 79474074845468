import { gql } from 'graphql-request'

export const ADD_USED_TAGS = gql`
  mutation AddUsedTags($addUsedTagsInput: AddUsedTagsInput!) {
    addUsedTags(addUsedTagsInput: $addUsedTagsInput) {
      id
      name
    }
  }
`
