import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useRouter } from 'next/router'

import { useModal } from '@hooks/contexts/ModalContext/ModalContext'
import { SearchByEnum } from '@interfaces/SearchByEnum'
import { useAuthentication } from '@hooks/useAuthentication'
import { useTagAction } from '@hooks/tag/useTagAction'
import { StoryCardAcceptProps, StoryCardProps } from './interface'

export function withStoryCard(Component: React.FC<StoryCardProps>) {
  function WithStoryCard({
    id,
    categoryId,
    bookType,
    category,
    tags,
    isShowStatus,
    status,
    direction = 'horizontal',
    ...props
  }: StoryCardAcceptProps) {
    const { user, isAuthenticated } = useAuthentication()
    const router = useRouter()
    const modal = useModal({ modal: 'categoryModal' })
    const tagClient = useTagAction()
    const isReplace =
      router.pathname.includes('/search') && router.pathname !== '/'

    const categoryPath = useMemo(() => {
      if (categoryId) {
        return {
          pathname: '/category',
          query: {
            id: categoryId,
            ...(bookType !== 'EBOOK' ? { bookType } : { isEbook: true }),
          },
        }
      }
      return {}
    }, [bookType, categoryId])

    const { mutate: createUserTagClick } = useMutation((tagsId: number) =>
      tagClient.createUserTagClick({
        userId: isAuthenticated ? user.id : undefined,
        tagsId,
        categoriesId: categoryId,
      })
    )

    function handleGoToSearchTagPage(tagName: string) {
      if (isReplace) {
        return {
          pathname: '/search/story',
          query: {
            ...router.query,
            text: encodeURIComponent(tagName),
            tab: SearchByEnum.TAG,
          },
        }
      }

      return {
        pathname: '/search/story',
        query: {
          text: encodeURIComponent(tagName),
          tab: SearchByEnum.TAG,
        },
      }
    }

    function showCategoryDialog() {
      modal.show({
        category,
        tags,
        categoryPath,
        handleGoToSearchTagPage,
        createUserTagClick,
      })
    }

    const componentProps = {
      id,
      categoryId,
      bookType,
      category,
      tags,
      status,
      direction,
      categoryPath,
      isShowStatus,
      isReplace,
      showCategoryDialog,
      handleGoToSearchTagPage,
      createUserTagClick,
      ...props,
    }

    return <Component {...componentProps} />
  }

  return WithStoryCard
}
