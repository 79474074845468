import cn from 'classnames'

import { abbreviateNumber } from '@lib/utils'
import { BulletListIcon } from '@components/icons/BulletListIcon'
import { EyeIcon } from '@components/icons/EyeIcon'
import { HeartIcon } from '@components/icons/HeartIcon'
import { HeartSolidIcon } from '@components/icons/HeartSolidIcon'

interface MobileTabletFooterProps {
  id: number
  view: number
  chapter: number
  like: number
  chapterPurchasedCount?: number
  direction?: string
  isLiked: boolean
  isToggleBookLike?: boolean
  isPurchased?: boolean
  handleLikeBook?: (id: number) => Promise<void>
}

export function MobileTabletFooter({
  id,
  view,
  chapter,
  like,
  chapterPurchasedCount,
  direction,
  isLiked,
  isToggleBookLike,
  isPurchased,
  handleLikeBook,
}: MobileTabletFooterProps) {
  return (
    <section className='flex justify-between items-center text-[12px] font-light text-secondary desktop:hidden'>
      <div
        className={cn('flex flex-col items-center', {
          'flex-col': direction === 'vertical',
        })}
      >
        <BulletListIcon width='18' height='18' />
        <span className='text-secondary-1'>{abbreviateNumber(chapter)}</span>
      </div>
      <div
        className={cn('flex flex-col items-center', {
          'flex-col': direction === 'vertical',
        })}
      >
        <EyeIcon width='18' height='18' />
        <span className='text-secondary-1'>{abbreviateNumber(view)}</span>
      </div>

      <button
        className={cn(
          'px-[10px] py-[4px] border border-gray-4 rounded-[8px] flex flex-col justify-center items-center',
          {
            'text-secondary': isLiked,
            'text-secondary-1': !isLiked,
            hidden: isPurchased,
          }
        )}
        type='button'
        disabled={isToggleBookLike || !handleLikeBook}
        onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
          if (handleLikeBook) {
            event.stopPropagation()
            await handleLikeBook(id)
          }
        }}
      >
        <HeartIcon
          className={cn('text-secondary', {
            'mr-0': direction === 'horizontal',
            'flex-col': direction === 'vertical',
            hidden: isLiked,
          })}
          width='20'
          height='20'
        />
        <HeartSolidIcon
          className={cn('text-red', {
            'flex-col': direction === 'vertical',
            hidden: !isLiked,
          })}
          width='20'
          height='20'
        />
        <span className='text-secondary-1'>{abbreviateNumber(like)}</span>
      </button>
      {isPurchased && (
        <div className='bg-blue-2 px-[10px] py-[6px] rounded-[4px] text-[12px] font-right text-primary flex flex-col'>
          <span>ซื้อแล้ว &nbsp;</span>
          <span>{abbreviateNumber(chapterPurchasedCount || 0)} ตอน</span>
        </div>
      )}
    </section>
  )
}
