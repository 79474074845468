import cn from 'classnames'
import { BookStatusEnum } from '@interfaces/BookStatusEnum'

export function BookStatus({
  value,
  className,
}: {
  value: BookStatusEnum
  className?: string
}) {
  return (
    <div
      className={cn(
        'flex items-center h-[24px] font-light text-[12px] px-[10px] rounded-[4px] whitespace-nowrap',
        {
          'border border-gray text-secondary-1': value === BookStatusEnum.DRAFT,
          'bg-blue-2 text-primary': value === BookStatusEnum.PUBLISHED,
        },
        className
      )}
    >
      {value === BookStatusEnum.PUBLISHED ? 'เผยแพร่' : 'แบบร่าง'}
    </div>
  )
}
