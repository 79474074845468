import { gql } from 'graphql-request'

export const GET_RECOMMENDATION_TAGS = gql`
  query GetRecommendationTags($categoryId: Int) {
    recommendationTags(categoryId: $categoryId) {
      data {
        id
        name
      }
    }
  }
`
