import { FunctionComponentType } from '@interfaces/commons/FunctionComponent'
import cn from 'classnames'

export function Container({ children, className }: FunctionComponentType) {
  return (
    <div
      className={cn(
        'max-w-[1166px] w-full flex-1 mx-auto desktop-tablet:py-[20px] tablet:px-[15px] mobile:pt-[20px] mobile:pb-[30px]',
        className
      )}
    >
      {children}
    </div>
  )
}
