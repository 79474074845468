import { gql } from 'graphql-request'

export const REMOVE_USED_TAGS = gql`
  mutation RemoveUsedTags($removeUsedTagsInput: AddUsedTagsInput!) {
    removeUsedTags(removeUsedTagsInput: $removeUsedTagsInput) {
      id
      name
    }
  }
`
