import { plainToInstance } from 'class-transformer'

import { ADD_USED_TAGS } from '@client/collections/Tag/schemas/addUsedTags'
import { CREATE_TAG } from '@client/collections/Tag/schemas/createTag'
import { GET_RECOMMENDATION_TAGS } from '@client/collections/Tag/schemas/getRecommendationTags'
import { REMOVE_USED_TAGS } from '@client/collections/Tag/schemas/removeUsedTags'
import { SEARCH_TAGS } from '@client/collections/Tag/schemas/searchTags'
import { CREATE_USER_TAG_CLICK } from '@client/collections/Tag/schemas/createUserTagClick'
import { TagType } from '@models/story/TagType'
import { RecommendTagType } from '@models/tag/RecommendTag'
import { gqlApiInstance } from '@client/init'

export function useTagAction() {
  async function getRecommendTagsByCategoryId(
    categoryId?: number
  ): Promise<RecommendTagType[]> {
    const { recommendationTags } = await gqlApiInstance.request(
      GET_RECOMMENDATION_TAGS,
      {
        categoryId,
      }
    )

    return plainToInstance(RecommendTagType, recommendationTags.data as any[])
  }

  async function searchTags(
    categoryId?: number,
    name?: string
  ): Promise<TagType[]> {
    const res = await gqlApiInstance.request(SEARCH_TAGS, {
      searchTagInput: {
        categoryId,
        name,
      },
    })

    return plainToInstance(TagType, res.searchTags as any[])
  }

  async function createTag(name: string, categoryId: number): Promise<any> {
    const res = await gqlApiInstance.request(CREATE_TAG, {
      createTagInput: {
        name,
        categoryIds: [categoryId],
      },
    })

    return res.createTag
  }

  async function addUsedTags(
    tagIds: number[],
    categoryId: number
  ): Promise<number[]> {
    const res = await gqlApiInstance.request(ADD_USED_TAGS, {
      addUsedTagsInput: {
        tagIds,
        categoryId,
      },
    })

    return res.addUsedTags.map((tag: any) => tag.id)
  }

  async function removeUsedTags(
    tagIds: number[],
    categoryId: number
  ): Promise<any> {
    const res = await gqlApiInstance.request(REMOVE_USED_TAGS, {
      removeUsedTagsInput: {
        tagIds,
        categoryId,
      },
    })

    return res.removeUsedTags
  }

  async function createUserTagClick({
    userId,
    tagsId,
    categoriesId,
  }: {
    userId?: number
    tagsId: number
    categoriesId: number
  }): Promise<void> {
    await gqlApiInstance.request(CREATE_USER_TAG_CLICK, {
      input: {
        userId,
        tagsId,
        categoriesId,
      },
    })
  }

  return {
    getRecommendTagsByCategoryId,
    searchTags,
    createTag,
    addUsedTags,
    removeUsedTags,
    createUserTagClick,
  }
}
