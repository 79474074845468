import { gql } from 'graphql-request'

export const SEARCH_TAGS = gql`
  query SearchTags($searchTagInput: SearchTagInput!) {
    searchTags(searchTagInput: $searchTagInput) {
      id
      name
      usedCount
    }
  }
`
